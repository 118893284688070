.carousel-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .carousel-item {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 20px;
    margin: 10px;
    border-radius: 4px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .slick-dots {
    bottom: -30px;
  }
  
  .slick-dots li button:before {
    font-size: 12px;
    color: #bbb;
  }